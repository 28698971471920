input.form-field {
  height: 48px;
  border: 1px solid #dcdcdc;
  font-family: Montserrat, sans-serif;
}

textarea.form-field {
  font-family: Montserrat, sans-serif;
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
  border: 1px solid #dcdcdc;
  color: #1e1e29;
  font-size: 16px;
}

.MuiPickersToolbarText-toolbarTxt {
  color: black !important;
}

.date-picker .MuiInputBase-root {
  background: #FFFF;
  border-radius: 26px;
  height: 48px;
  border: 1px solid #dcdcdc;
}
.date-picker .MuiInputBase-root input {
  padding: 10px 16px;
  color: #1e1e29;
  font-size: 16px;
}
.date-picker .MuiInputBase-root::before {
  display: none;
  border-bottom: none;
}
.date-picker .MuiInputBase-root::after {
  display: none;
  border-bottom: none;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background: linear-gradient(270deg, #813EDA 0%, #4123D7 100%);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 31px;
  line-height: 32px;
}

.MuiPickersBasePicker-pickerView {
  background-color: #f2f1f1;
}

.MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-iconButton {
  background-color: transparent;
}

.MuiDialogActions-root .MuiButton-text {
  width: 50%;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #512AD8;
}

.MuiDialogActions-root:first-child.MuiButton-text {
  color: #1e1e29 !important;
}

.MuiPickersCalendar-week .MuiPickersDay-daySelected {
  background-color: #512AD8;
}

.MuiFormControl-root.date-picker {
  width: 100%;
  margin: 10px 0 15px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiToolbar-gutters .go-to-current-datetime {
  color: #7B66E3;
  background-color: #EDEFF7;
  font-weight: 400;
  border-radius: 8px;
  min-height: 24px;
  width: auto;
  padding: 0 15px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiToolbar-gutters .close-icon {
  background-color: #e6e7f2;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDialogContent-root .MuiPickersBasePicker-pickerView {
  background-color: #fff;
}

.MuiDialogContent-root .MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-transform: capitalize;
}

.MuiDialogContent-root .MuiPickersCalendarHeader-switchHeader .MuiIconButton-label {
 background-color: #e7e7e7;
 border-radius: 8px;
 color: #000;
}

.MuiDialogActions-root {
  justify-content: center !important;
}

.MuiDialogActions-root button:first-child {
  display: none;
}

.MuiDialogActions-root button:nth-child(2) {
  color: #fff;
  background: linear-gradient(270deg, #813eda 0%, #4123d7 100%);
  border-radius: 26px;
  border: 0;
  box-shadow: 0 4px 4px rgba(218, 218, 218, 0.25);
  width: 100%;
  margin-bottom: 20px;
}

.MuiDialogActions-root button:nth-child(2) .MuiButton-label {
  font-size: 15px;
  text-transform: initial;
  padding: 10px 0;
}

.MuiToolbar-root {
  display: flex !important;
  justify-content: space-between !important;
  align-items: baseline !important;
  padding-top: 10px;
  background: transparent !important;
}

.MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  border-radius: 18px;
}

.go-to-current-time {
  position: absolute;
  top: 30px;
  background-color: #EDEFF7 !important;
  color: #7B66E3 !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  min-height: 24px !important;
  width: auto !important;
  padding: 0 15px;
}
