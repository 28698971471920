@import 'assets/css/date-picker.css';

@font-face {
    font-family: Montserrat;
    src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.car-rectangle {
  width: 82px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}

.car-rectangle-inner {
  position: relative;
}

.field-error {
  font-size: 12px;
  color: #ff3b30;
  margin-top: -5px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type='file'] {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sigCanvas {
  width: 100%;
  height: 220px;
}

ul {
  list-style: none;
}
